import { alpha, darken, lighten, Theme } from "@material-ui/core";
import { Override } from "../types";
import { ObjectEnum } from "../types/enums";
import {
  AssistDetails as AssistDetailsDto,
  AssistType as AssistTypeDto,
  EventColor as EventColorDto,
  EventResponseStatus,
  EventSubType as EventSubTypeDto,
  EventType as EventTypeDto,
} from "./client";
import { User } from "./Users";

export { EventResponseStatus } from "./client";
export { EventColorDto as EventColorEnum };

export enum AssistStatus {
  Controlled = "CONTROLLED",
  Released = "RELEASED",
  Archived = "ARCHIVED",
}

export class AssistType extends ObjectEnum<AssistTypeDto> {
  static CatchupAm = new AssistType(AssistTypeDto.CATCHUP_AM);
  static CatchupPm = new AssistType(AssistTypeDto.CATCHUP_PM);
  static Lunch = new AssistType(AssistTypeDto.LUNCH);
  static Focus = new AssistType(AssistTypeDto.FOCUS);
  static PreTravel = new AssistType(AssistTypeDto.TRAVEL_PRE);
  static PostTravel = new AssistType(AssistTypeDto.TRAVEL_POST);
  static CustomHabit = new AssistType(AssistTypeDto.CUSTOM_DAILY);
  static Task = new AssistType(AssistTypeDto.TASK);
  static Buffer = new AssistType(AssistTypeDto.CONBUF);

  constructor(public key: AssistTypeDto) {
    super(key);
  }

  isTravel(): boolean {
    return this === AssistType.PreTravel || this === AssistType.PostTravel;
  }
  isTask(): boolean {
    return this == AssistType.Task;
  }
  isHabit() {
    return !this.isTravel() && !this.isTask();
  }
}

export type AssistDetails = Override<
  AssistDetailsDto,
  {
    type?: AssistType;
    status?: AssistStatus;
    dailyHabitId?: number;
    taskId?: number;
    taskIndex?: number;
  }
>;

export interface Colorized {
  getColor(): EventColor;
}

export type EventColorStr = `${EventColorDto}` | "AUTO";

export class EventColor extends ObjectEnum<EventColorDto | string> {
  private static _all: EventColor[] = [];
  static get all(): readonly EventColor[] {
    return this._all;
  }

  static Auto = new EventColor("AUTO", undefined, "rgba(255,255,255,0.25)", "Auto", true);
  static None = new EventColor(EventColorDto.NONE, null, "#AAA", "Calendar Default", true);
  static Lavender = new EventColor(EventColorDto.LAVENDER, "1", "#6E7BC4", "Lavender", true);
  static Sage = new EventColor(EventColorDto.SAGE, "2", "#2DAD6E", "Sage", true);
  static Grape = new EventColor(EventColorDto.GRAPE, "3", "#8321A0", "Grape", true);
  static Flamingo = new EventColor(EventColorDto.FLAMINGO, "4", "#E27068", "Flamingo", true);
  static Banana = new EventColor(EventColorDto.BANANA, "5", "#F5B623", "Banana", true);
  static Tangerine = new EventColor(EventColorDto.TANGERINE, "6", "#F2471C", "Tangerine", true);
  static Peacock = new EventColor(EventColorDto.PEACOCK, "7", "#0191E1", "Peacock", true);
  static Graphite = new EventColor(EventColorDto.GRAPHITE, "8", "#565656", "Graphite", true);
  static Blueberry = new EventColor(EventColorDto.BLUEBERRY, "9", "#3748AC", "Blueberry", true);
  static Basil = new EventColor(EventColorDto.BASIL, "10", "#0E753B", "Basil", true);
  static Tomato = new EventColor(EventColorDto.TOMATO, "11", "#CF0003", "Tomato", true);

  static get(key?: EventColorStr): EventColor {
    if (!key) return EventColor.Auto;
    return (
      Object.values(this)
        .filter((val) => val instanceof EventColor)
        .find((val) => val.key === key) || EventColor.Auto
    );
  }

  static getColor(user: User | null, category?: Category): EventColor {
    if (!category) return EventColor.Auto;
    if (!user) return category.typeOrSubtype?.getColor() || EventColor.Auto;

    return user.features.colors.categories[category.parent?.key || category.key] || EventColor.Auto;
  }

  /**
   * Gets background and text colors for an event's color.
   * @param theme The MUI theme
   * @param color The color (as a string) from the event
   * @param rsvpStatus The RSVP status of the event
   * @param isPast Should the event be displayed as a past event
   * @returns A tuple containing [backgroundColor, textColor]
   */
  static getColorShades(
    theme: Theme,
    color: string,
    rsvpStatus: EventResponseStatus,
    isPast = false
  ): [string, string, string] {
    if (rsvpStatus === "Declined" || rsvpStatus === "TentativelyAccepted" || rsvpStatus === "NotResponded")
      return [lighten(color, 0.8), darken(color, 0.2), color];
    else if (isPast) return [lighten(color, 0.75), darken(color, 0.9), lighten(color, 0.75)];
    else return [lighten(color, 0.3), alpha(theme.palette.getContrastText(color), 0.8), lighten(color, 0.3)];
  }

  constructor(
    public readonly key: EventColorStr,
    public readonly id: string | null | undefined,
    public readonly hex: string,
    public readonly label: string,
    public readonly builtin?: boolean
  ) {
    super(key);

    if (builtin) EventColor._all.push(this);
  }

  toJSON() {
    return this.key === "AUTO" ? null : super.toJSON();
  }
}

abstract class EventCategory extends ObjectEnum<EventTypeDto | EventSubTypeDto> implements Colorized {
  static parse(key: string): EventCategory {
    if (!key) return EventType.Meeting;
    return EventSubType.get(key) || EventType.get(key);
  }

  abstract getColor(): EventColor;
  abstract get type(): EventType;
  abstract get subType(): EventSubType;
}

abstract class ScoredType extends EventCategory {
  type: EventType;
  subType: EventSubType;
}

export type EventTypeStr = `${EventTypeDto}`;

export class EventType extends EventCategory {
  static Personal = new EventType(EventTypeDto.PERSONAL, EventColor.Flamingo, "Personal");
  static Work = new EventType(EventTypeDto.WORK, EventColor.Blueberry, "Solo Work");
  static Meeting = new EventType(EventTypeDto.MEETING, EventColor.Graphite, "Meeting");
  static Logistics = new EventType(EventTypeDto.LOGISTICS, EventColor.Tangerine, "Logistics");

  static get(key?: string): EventType {
    if (!key) return EventType.Meeting;

    return Object.values(this)
      .filter((val) => val instanceof EventType)
      .find((val) => val.key === key);
  }

  constructor(readonly key: EventTypeDto, readonly color: EventColor, readonly label: string) {
    super(key);
  }

  getColor() {
    return this.color;
  }

  get type() {
    return this;
  }

  get subType() {
    switch (this.key) {
      case EventTypeDto.WORK:
        return EventSubType.Focus;
      case EventTypeDto.LOGISTICS:
        return EventSubType.Travel;
      case EventTypeDto.PERSONAL:
        return EventSubType.PersonalOther;
      case EventTypeDto.MEETING:
      default:
        return EventSubType.Unknown;
    }
  }
}

export class EventSubType extends EventCategory implements ScoredType {
  static Unknown = new EventSubType(EventSubTypeDto.UNKNOWN, EventType.Meeting, "Unknown");

  static OneOnOne = new EventSubType(EventSubTypeDto.ONE_ON_ONE, EventType.Meeting, "1:1");
  static StaffMeeting = new EventSubType(EventSubTypeDto.STAFF_MEETING, EventType.Meeting, "Staff Meeting");
  static OpReview = new EventSubType(EventSubTypeDto.OP_REVIEW, EventType.Meeting, "Operations Review");
  static External = new EventSubType(EventSubTypeDto.EXTERNAL, EventType.Meeting, "External Meeting");
  static Ideation = new EventSubType(EventSubTypeDto.IDEATION, EventType.Meeting, "Ideation Meeting");

  static Focus = new EventSubType(EventSubTypeDto.FOCUS, EventType.Work, "Deep Solo Work");
  static Productivity = new EventSubType(EventSubTypeDto.PRODUCTIVITY, EventType.Work, "Shallow Solo Work");

  static Travel = new EventSubType(EventSubTypeDto.TRAVEL, EventType.Logistics, "Travel");
  static Flight = new EventSubType(EventSubTypeDto.FLIGHT, EventType.Logistics, "Flight");
  static Reclaim = new EventSubType(EventSubTypeDto.RECLAIM, EventType.Logistics, "Reclaim");

  static Vacation = new EventSubType(EventSubTypeDto.VACATION, EventType.Personal, "Vacation");
  static Health = new EventSubType(EventSubTypeDto.HEALTH, EventType.Personal, "Health");
  static Errand = new EventSubType(EventSubTypeDto.ERRAND, EventType.Personal, "Errand");
  static PersonalOther = new EventSubType(EventSubTypeDto.OTHER_PERSONAL, EventType.Personal, "Personal");

  static get(key: string) {
    return Object.values(this)
      .filter((val) => val instanceof EventSubType)
      .find((val) => val.key === key);
  }

  constructor(readonly key: EventSubTypeDto, readonly type: EventType, readonly label: string) {
    super(key);
  }

  get subType() {
    return this;
  }

  getColor() {
    return this.type.color;
  }
}

export abstract class Category extends ObjectEnum {
  static get All(): (PrimaryCategory | Subcategory)[] {
    return [...Object.values(PrimaryCategory), ...Object.values(Subcategory)].filter((val) => val instanceof Category);
  }

  static get Primary(): PrimaryCategory[] {
    return Object.values(PrimaryCategory).filter((c) => c instanceof PrimaryCategory);
  }

  static get(key: string) {
    return Category.All.find((val) => val.key === key);
  }

  abstract readonly parent?: PrimaryCategory;

  constructor(readonly typeOrSubtype: EventType | EventSubType, readonly label: string, readonly description: string) {
    super(typeOrSubtype.key);
  }
}

export class PrimaryCategory extends Category {
  static TeamMeeting = new PrimaryCategory(
    EventType.Meeting,
    "Team Meeting",
    "Meetings with people within your organization"
  );

  static SoloWork = new PrimaryCategory(EventType.Work, "Solo Work", "Focused work, including work Habits and Tasks");

  static Personal = new PrimaryCategory(
    EventType.Personal,
    "Personal",
    "Personal events, including personal Habits and Tasks"
  );

  static Logistics = new PrimaryCategory(
    EventType.Logistics,
    "Travel & Logistics",
    "Flights, travel time before and after events"
  );

  static ExternalMeeting = new PrimaryCategory(
    EventSubType.External,
    "External Meeting",
    "Meetings with people outside your organization"
  );

  static OneOnOne = new PrimaryCategory(
    EventSubType.OneOnOne,
    "One-on-One",
    "1:1s with people within your organization"
  );

  static TaskOption = [PrimaryCategory.SoloWork, PrimaryCategory.Personal];

  static HabitOption = [PrimaryCategory.SoloWork, PrimaryCategory.Personal, PrimaryCategory.Logistics];

  readonly parent?: PrimaryCategory = undefined;

  static get(key: string) {
    return Object.values(PrimaryCategory)
      .filter((c) => c instanceof PrimaryCategory)
      .find((val) => val.key === key);
  }

  constructor(readonly typeOrSubtype: EventType | EventSubType, readonly label: string, readonly description: string) {
    super(typeOrSubtype, label, description);
  }
}

export class Subcategory extends Category {
  static Focus = new Subcategory(EventSubType.Focus, "Focus Work", "", PrimaryCategory.SoloWork);
  static Productivity = new Subcategory(EventSubType.Productivity, "Productivity", "", PrimaryCategory.SoloWork);

  static Travel = new Subcategory(EventSubType.Travel, "Travel", "", PrimaryCategory.Logistics);
  static Flight = new Subcategory(EventSubType.Flight, "Flight", "", PrimaryCategory.Logistics);
  static Decompress = new Subcategory(EventSubType.Reclaim, "Decompression", "", PrimaryCategory.Logistics);

  static Vacation = new Subcategory(EventSubType.Vacation, "Vacation", "", PrimaryCategory.Personal);
  static PersonalOther = new Subcategory(EventSubType.PersonalOther, "Other Personal", "", PrimaryCategory.Personal);

  static Unknown = new Subcategory(EventSubType.Unknown, "Unknown", "", PrimaryCategory.TeamMeeting);

  static get(key: string) {
    return Object.values(Subcategory)
      .filter((c) => c instanceof PrimaryCategory)
      .find((val) => val.key === key);
  }

  constructor(
    readonly typeOrSubtype: EventType | EventSubType,
    readonly label: string,
    readonly description: string,
    readonly parent: PrimaryCategory
  ) {
    super(typeOrSubtype, label, description);
  }
}
